import Toolbar from '@material-ui/core/Toolbar';
import SettingsIcon from '@material-ui/icons/Settings';
import {
    CustomBreadcrumbs,
    CustomList,
    CustomSelectInput,
} from 'candy-commons-components';
import React from 'react';
import {
    BooleanField,
    CreateButton,
    TextField,
    useTranslate,
    FunctionField,
} from 'react-admin';
import CustomDatagrid from '../../components/organisms/customDatagrid';
import config from '../../config';

import options from './applianceWakeUp.mock';
import CommonApplianceFilter from '../../components/organisms/commonApplianceFilter';
import { useSelector } from 'react-redux';

import { getSeriesVersion } from '../../selectors/cmsConfig';
const AssetListAction = ({
    basePath,
    displayedFilters,
    filters,
    filterValues,
    resource,
    showFilter,
    permissions,
}) => {
    const username = localStorage.getItem(
        `CognitoIdentityServiceProvider.${config.userPoolWebClientId}.LastAuthUser`
    );
    const token = localStorage.getItem(
        `CognitoIdentityServiceProvider.${config.userPoolWebClientId}.${username}.idToken`
    );
    return (
        <Toolbar>
            {filters &&
                React.cloneElement(filters, {
                    resource,
                    showFilter,
                    displayedFilters,
                    filterValues,
                    context: 'button',
                })}
            {permissions && permissions.asset && permissions.asset.create && (
                <CreateButton basePath={basePath} />
            )}
        </Toolbar>
    );
};
const ApplianceWakeUpList = ({ permissions, ...props }) => {
    const transaltion = useTranslate();
    const seriesVersion = useSelector(getSeriesVersion);
    const breadcrumbsData = [
        {
            text: transaltion('resources.appliance-wake-up.name'),
            url: '',
            icon: <SettingsIcon />,
        },
    ];

    const getEnrollmentType = (val) => {
        return options?.find((o) => o.id === val)?.name || '';
    };
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <CustomList
                {...props}
                perPage={25}
                exporter={false}
                filters={
                    <CommonApplianceFilter
                        applianceType
                        brand
                        series
                        seriesVersion
                        optionalFilters={[
                            <CustomSelectInput
                                allowEmpty
                                source="options"
                                label="enrollment Type"
                                choices={options}
                                translateChoice={false}
                            />,
                        ]}
                    />
                }
                actions={<AssetListAction permissions={permissions} />}
                bulkActionButtons={
                    permissions && permissions.asset && permissions.asset.delete
                }
            >
                <CustomDatagrid
                    selectable
                    choiceColumns
                    rowClick="edit"
                    defaultColumns={[
                        'applianceType',
                        'brand',
                        'series',
                        'seriesVersion',
                        'options',
                        'resetGesture',
                        'singlePairing',
                        'easyEnrollment',
                        'standardEnrollment',
                    ]}
                >
                    <TextField source="applianceType" />
                    <TextField source="brand" />
                    <TextField source="series" />
                    <TextField source="seriesVersion" label="Series Version" />
                    <TextField source="loadingType" label="Loading Type" />
                    <TextField
                        source="installationType"
                        label="Installation Type"
                    />
                    <TextField
                        source="dryingTechnology"
                        label="Drying Technology"
                    />
                    <TextField source="display" label="Display" />
                    <FunctionField
                        render={(record) => getEnrollmentType(record.options)}
                        label="Enrollment Type"
                        source="options"
                    />
                    <BooleanField source="resetGesture" label="Reset Gesture" />
                    <BooleanField
                        source="singlePairing"
                        label="Single Pairing"
                    />
                    <BooleanField
                        source="easyEnrollment"
                        label="Easy Enrollment"
                    />
                    <BooleanField
                        source="standardEnrollment"
                        label="Standard Enrollment"
                    />
                </CustomDatagrid>
            </CustomList>
        </>
    );
};
export default ApplianceWakeUpList;
