import React, { useState } from 'react';
import Amplify, { Storage } from 'aws-amplify';
import {
    Create,
    SimpleForm,
    TextInput,
    required,
    FileField,
    TextField,
    useTranslate,
    FileInput,
    useCreate,
    useRedirect,
} from 'react-admin';
import {
    ContainerFieldsList,
    CustomBreadcrumbs,
} from 'candy-commons-components';
import languages from '../../data/languages.json';
import TranslateIcon from '@material-ui/icons/Translate';
import { CustomSelectArrayInput } from 'candy-commons-components';
const TranslationAI = (props) => {
    const translation = useTranslate();
    const redirect = useRedirect();
    const [create] = useCreate();
    const [file, setFile] = useState(null);
    const breadcrumbsData = [
        {
            text: translation('resources.translation-ai.name'),
            url: 'translation-ai',
            icon: <TranslateIcon />,
        },
        {
            text: translation('resources.translation-ai.create.name'),
            url: '',
        },
    ];
    const handleClick = async (event) => {
        const file = event;
        if (!file) {
            return;
        }
        setFile(file);
    };
    const onCreate = async (data) => {
        try {
            const key = `translation_inputs/${file.name}`;
            const upload = await Storage.put(key, file, {
                contentType: file.type,
            });
            create('translation-ai', data);
            setTimeout(() => {
                redirect('list', 'translation-ai');
            }, 2000);
        } catch (err) {
            console.log(err);
        }
    };
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <Create {...props}>
                <SimpleForm redirect="list" save={onCreate}>
                    <ContainerFieldsList>
                        <CustomSelectArrayInput
                            label="languages"
                            source="languages"
                            choices={languages.filter((l) => l.id !== null)}
                            validate={required()}
                            alwaysOn
                        />
                    </ContainerFieldsList>
                    <FileInput
                        source="translationsFile"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        validate={required()}
                        onChange={handleClick}
                        placeholder={
                            <p>
                                {translation(
                                    'resources.asset.fields.input_file'
                                )}
                            </p>
                        }
                    >
                        <FileField source="src" title="title" />
                    </FileInput>
                </SimpleForm>
            </Create>
        </>
    );
};
export default TranslationAI;
